import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InitialPurchaseRequestFormService extends BaseFormService {
  constructor(httpClient: HttpClient, utilityService: UtilityService) {
    super(`${environment.apiUrl}forms/clients/$$clientId$$/initial-purchase-request-form`, httpClient, utilityService);
  }
  submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
    return this.httpClient
      .post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
        userId,
      })
      .pipe();
  }
  changeStatus(clientId: string, id: string) {
    return this.httpClient
      .get<any>(`${this.getUrl(clientId)}/${id}/change-status`)
      .pipe();
  }
  getAllInitialPurchaseRequests(clientId: string, type: any, vendorId: any, purchaseFormId: any) {
    return this.httpClient
      .get<any>(`${this.getUrl(clientId)}/${type}/${vendorId}/search?purchaseFormId=${purchaseFormId}`)
      .pipe();
  }

  getInitialPurchaseRequestById(clientId: string, id: any) {
    return this.httpClient
      .get<any>(`${this.getUrl(clientId)}/${id}`)
      .pipe();
  }
}
