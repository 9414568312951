import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { UserService } from 'app/services/user.service';
import { LoadingService } from 'app/services/loading.service';
import { ScriptService } from 'app/services/ScriptService';
import { UtilityService } from './services/utility.service';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
	loading = false;
	loadingChanges = [];

	constructor(
		private loadingService: LoadingService,
		private cdref: ChangeDetectorRef,
		private userService: UserService,
		private scriptService: ScriptService,
		private utilityService: UtilityService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		if (userService.currentUserValue?.user) {
			let clientId = userService.currentUserValue.user.clientId;
			if (!clientId) {
				clientId = localStorage.getItem(UserService.clientIdCookie);
			}

			if (clientId) {
				this.userService.setClientId(clientId);
				this.utilityService.retrieveConfiguration(clientId, null, false);
			}
			userService.setDatadogRUMUser();
		}
	}

	ngOnInit() {
		if (environment.production) {
			this.scriptService
				.load('ze-snippet')
				.then((_) => {})
				.catch((error) => console.log(error));
		}

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				let eventUrl = event?.url;
				if (eventUrl === '/') {
					eventUrl = '/dashboard';
				}

				datadogRum.startView({ name: eventUrl });
				this.loadingService.OnLoading.emit(true);
			} else if (event instanceof NavigationEnd) {
				this.loadingService.OnLoading.emit(false);
			}
		});

		this.userService.validateMappings();
		if (!localStorage.getItem(UserService.mappingName)) {
			this.userService.getFormMappingAll().subscribe((data) => {
				this.userService.setFormMappings(data);
			});
		}
		this.loadLazyScripts();
	}

	ngOnDestroy() {
	}

	resetLoading() {
		this.loadingChanges = [];
		this.loading = false;
		try {
			if (this.cdref) {
				this.cdref.detectChanges();
			}
		} catch (e) {}
	}

	startTimer() {
		setTimeout(this.resetLoading, 10000);
	}

	ngAfterContentChecked(): void {
		this.loadingService.OnLoading.subscribe((data: boolean) => {
			let needsChange = false;
			if (this.loadingChanges.length == 0 && data) {
				needsChange = true;
			}
			if (data) {
				this.loadingChanges.push(data);
			} else {
				if (this.loadingChanges.length > 0) {
					this.loadingChanges.pop();
				}
			}

			if (this.loadingChanges.length == 0 && !data) {
				needsChange = true;
			}

			if (this.loading != data && needsChange) {
				this.loading = data;
				this.cdref.detectChanges();
			}
		});
	}

	@HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
		this.disableScroll(event);
	}

	@HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
		this.disableScroll(event);
	}

	@HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
		this.disableScroll(event);
	}

	disableScroll(event: any) {
		if (event.srcElement.type === 'number') {
			event.preventDefault();
		}
	}

	loadLazyScripts() {
		this.scriptService
			.load('pdfmake-pdfmake')
			.then((_) => {})
			.catch((error) => console.log(error));
		this.scriptService
			.load('pdfmake-vfs_fonts')
			.then((_) => {})
			.catch((error) => console.log(error));
		this.scriptService
			.load('exceljs')
			.then((_) => {})
			.catch((error) => console.log(error));
	}
}
