import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from 'app/services/BaseFormService';
import { environment } from 'environments/environment';
import { CheckRequestView } from 'app/models/form/CheckRequestView';

@Injectable({
	providedIn: 'root',
})
export class SpecialPaymentServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/special-payment`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CheckRequestView> {
		return this.httpClient
			.post<CheckRequestView>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}
}
