import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentUser = this.userService.currentUserValue;
		const isLoggedIn = currentUser && currentUser.token;
		const isApiUrl = request.url.startsWith(environment.apiUrl);
		const isMSLoginUrl = request.url.includes('authentication/mslogin');
		if (isApiUrl) {
			var token = '';
			if (isMSLoginUrl) {
				token = this.userService.getMsalAccessToken();
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
			}
			else if (isLoggedIn) {
				token = currentUser.token;
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
			} 
		}

		return next.handle(request);
	}
}
