import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserCredentials } from '../models/UserCredentials';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModifyResponse } from '../models/UserModifyResponse';
import { User } from '../models/User';
import { map } from 'rxjs/operators';
import { UserLogin } from '../models/UserLogin';
import { Role } from '../models/Role';
import { AvailableForm } from '../models/AvailableForm';
import { WorkflowForm } from '../models/WorkflowForm';
import { RoleItemDto } from 'app/models/RoleItem';
import { environment } from 'environments/environment';
import { UtilityService } from 'app/services/utility.service';
import { ClaimItem } from 'app/models/ClaimItem';
import { datadogRum } from '@datadog/browser-rum';
import { MsalService } from '@azure/msal-angular';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly currentUserSubject: BehaviorSubject<UserLogin>;
	public currentUser: Observable<UserLogin>;
	public static cookieName = 'userInfo';
	public static mappingName = 'userFormMapping';
	public static clientIdCookie = 'selectedClientId';
	public static msTokenCookie = 'msTokenCookie';
	public static msalAccount = 'msalAccount';
	public availableForms: AvailableForm[] = [];
	availableFormsEvent: EventEmitter<AvailableForm[]> = new EventEmitter<AvailableForm[]>();

	constructor(private httpClient: HttpClient, private utilityService: UtilityService, private authService: MsalService,) {
		const user = localStorage.getItem(UserService.cookieName) || sessionStorage.getItem(UserService.cookieName);
		this.currentUserSubject = new BehaviorSubject<UserLogin>(JSON.parse(user));
		this.currentUser = this.currentUserSubject.asObservable();
		this.setDatadogRUMUser();
	}

	public get currentUserValue(): UserLogin {
		return this.currentUserSubject.value;
	}

	public get formMapping(): any {
		return JSON.parse(
			sessionStorage.getItem(UserService.mappingName) || localStorage.getItem(UserService.mappingName) || '""'
		);
	}

	// returns available form id
	public getFormKeyByName(name) {
		let data = this.formMapping;
		for (const key in this.formMapping) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				const element = data[key];
				if (element == name) {
					return key;
				}
			}
		}
		return '';
	}

	public setDatadogRUMUser() {
		if (this.currentUserValue && this.currentUserValue.user && this.currentUserValue.user!=null)
		datadogRum.setUser({
			id: this.currentUserValue.user.id,
			name: this.currentUserValue.user.fullName,
			email: this.currentUserValue.user.email,
			clientId: this.currentUserValue.user.clientId
		});
	}

	resetPassword(model: UserCredentials): Observable<UserModifyResponse> {
		return this.httpClient.post<UserModifyResponse>(`${environment.apiUrl}authentication/reset-password`, model).pipe();
	}

	requestPassword(model: UserCredentials): Observable<UserModifyResponse> {
		return this.httpClient
			.post<UserModifyResponse>(`${environment.apiUrl}authentication/request-reset-password`, model)
			.pipe();
	}

	login(email: string, password: string): Observable<UserLogin> {
		return this.httpClient.post<UserLogin>(`${environment.apiUrl}authentication/login`, { email, password }).pipe();
	}

	mslogin(email: string, password: string): Observable<UserLogin> {
		return this.httpClient.post<UserLogin>(`${environment.apiUrl}authentication/mslogin`, { email, password }).pipe();
	}

	unlockUserAccount(email: string): Observable<UserModifyResponse> {
		return this.httpClient
			.post<UserModifyResponse>(`${environment.apiUrl}authentication/unlock-user-account`, { email })
			.pipe();
	}
	validateMappings() {
		if (!localStorage.getItem(UserService.mappingName)) {
			this.getFormMappingAll().subscribe((data) => {
				this.setFormMappings(data);
			});
		}
	}

	completeLogIn(userLogin, remember) {
		let selectedClientId = localStorage.getItem(UserService.clientIdCookie);
		let msalAccount = localStorage.getItem(UserService.msalAccount);
		localStorage.clear();
		sessionStorage.clear();
		this.validateMappings();

		this.setClientIdIfEmpty(userLogin.user); // set client id if client id is empty, due to logic shift in multi client
		// store user details and jwt token in local storage to keep user logged in between page refreshes

		if (remember) {
			localStorage.setItem(UserService.cookieName, JSON.stringify(userLogin));
		} else {
			sessionStorage.setItem(UserService.cookieName, JSON.stringify(userLogin));
		}
		if (msalAccount) localStorage.setItem(UserService.msalAccount, msalAccount);
		if (selectedClientId) this.setClientId(selectedClientId);
		else this.setClientIdIfEmpty(userLogin.user); // set client id if client id is empty, due to logic shift in multi client
		// store user details and jwt token in local storage to keep user logged in between page refreshes

		this.currentUserSubject.next(userLogin);
		if (userLogin.user && userLogin.user !=null)
		datadogRum.setUser({
			id: userLogin.user.id,
			name: userLogin.user.fullName,
			email: userLogin.user.email,
			clientId: userLogin.user.clientId
		});
		this.currentUserSubject
			.asObservable()
			.toPromise()
			.then(() => {
				location.reload();
			});
	}

	setClientId(clientId: string) {
		let currentStorageIsLocal = true;
		let userData = localStorage.getItem(UserService.cookieName);
		if (!userData) {
			userData = sessionStorage.getItem(UserService.cookieName);
			if (userData) {
				currentStorageIsLocal = false;
			}
		}

		localStorage.setItem(UserService.clientIdCookie, clientId);

		if (userData) {
			let userLogin = JSON.parse(userData) as UserLogin;
			userLogin.user.clientId = clientId;
			if (currentStorageIsLocal) {
				localStorage.setItem(UserService.cookieName, JSON.stringify(userLogin));
			} else {
				sessionStorage.setItem(UserService.cookieName, JSON.stringify(userLogin));
			}
			this.currentUserSubject.next(userLogin);
			this.setDatadogRUMUser();
		}
	}

	logout() {
		const userId = this.currentUserValue?.user?.id;
		const token = this.currentUserValue.refreshToken;
		this.logoutApi(userId, token).subscribe(() => {
			localStorage.removeItem(UserService.cookieName);
			sessionStorage.removeItem(UserService.cookieName);
			sessionStorage.clear();
			localStorage.clear();
			this.currentUserSubject.next(null);
			datadogRum.clearUser();
		});
	}

	setFormMappings(data: any) {
		localStorage.setItem(UserService.mappingName, JSON.stringify(data));
	}

	getUsers(): Observable<User[]> {
		return this.httpClient.get<User[]>(`${environment.apiUrl}users`).pipe();
	}
	
	getIpAddress(): Observable<any> {
		return this.httpClient.get<any>(`https://ipv4.myexternalip.com/json`).pipe();
	}

	getAclarianUsers(): Observable<User[]> {
		return this.httpClient.get<User[]>(`${environment.apiUrl}users/aclarian`).pipe();
	}

	getUsersForClient(clientId: string): Observable<User[]> {
		return this.httpClient.get<User[]>(`${environment.apiUrl}users/clients/${clientId}`).pipe();
	}

	getUsersByClaim(clientId: string,claimValue:string): Observable<User[]> {
		return this.httpClient.get<User[]>(`${environment.apiUrl}users/clients/${clientId}/claim/${claimValue}`).pipe();
	}
	getUser(id: string): Observable<User> {
		return this.httpClient.get<User>(`${environment.apiUrl}users/${id}`).pipe();
	}

	resetMfa(id: string): Observable<any> {
		return this.httpClient.delete(`${environment.apiUrl}users/${id}/resetmfa`).pipe();
	}

	updateUser(item: User): Observable<UserModifyResponse> {
		return this.httpClient.put<UserModifyResponse>(`${environment.apiUrl}users/${item.id}`, item).pipe();
	}

	createUser(item: User): Observable<UserModifyResponse> {
		return this.httpClient.post<UserModifyResponse>(`${environment.apiUrl}users`, item).pipe();
	}

	addRemoveUserToRoles(userId: string, roles: Role[]): Observable<any> {
		return this.httpClient.put<User>(`${environment.apiUrl}users/${userId}/roles`, roles).pipe();
	}

	addRemoveUserToPortalRoles(userId: string, clientId: string, role: RoleItemDto): Observable<any> {
		return this.httpClient.put<User>(`${environment.apiUrl}users/${userId}/${clientId}/portalroles`, role).pipe();
	}

	addRemoveUserToRoleItems(userId: string, clientId: string, roles: RoleItemDto): Observable<any> {
		return this.httpClient.put<User>(`${environment.apiUrl}users/${userId}/${clientId}/roles/items`, roles).pipe();
	}

	getClaimItems(userId: string, clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${environment.apiUrl}users/${userId}/clientId/${clientId}/claims`).pipe();
	}

	getUserRoles(userId: string): Observable<Role[]> {
		return this.httpClient.get<Role[]>(`${environment.apiUrl}users/${userId}/roles`).pipe();
	}

	getFormMappingAll(): Observable<any> {
		return this.httpClient.get<any>(`${environment.apiUrl}users/mapping/forms/all`).pipe();
	}

	addRemoveUserDepartments(userId: string, clientId: string, departments: any[]): Observable<any> {
		return this.httpClient.put<any>(`${environment.apiUrl}users/${userId}/${clientId}/departments`, departments).pipe();
	}

	isAdmin(): boolean {
		if (this.hasRole('SYS_ADMIN')) {
			return true;
		}

		let currentClientId = this.currentUserValue?.user?.clientId;
		if (currentClientId == environment.aclarianId) {
			return false;
		}

		return this.hasRole('Aclarian Support');
	}

	isAuthorizedUser(): boolean {
		if (this.currentUserValue != null) {
			return this.currentUserValue.user.claims;
		}
		return false;
	}

	///deprecated
	isClientAdmin(clientId): boolean {
		return this.isAdmin();
	}

	hasRole(roleName: string) {
		if (this.currentUserValue != null) {
			const role = this.currentUserValue.user.roles.find((t) => t.name === roleName);
			return role != null || role != undefined;
		}
		return false;
	}

	isAclarianEmployee() {
		let result = false;
		if (this.currentUserValue && this.currentUserValue.user) {
			result = this.currentUserValue.user.isAclarianEmployee;
		}

		return result;
	}

	canEditFormById(formId: string) {
		return this.getClaim(formId)?.edit == true || this.isAdmin();
	}

	canVisibleFormById(formId: string) {
		return this.getClaim(formId)?.visible == true || this.isAdmin();
	}

	isAdminToEditForm(clientId: string, formId: string = '') {
		return this.isAdmin() || this.getClaim(formId)?.edit == true;
	}

	getClaim(claimId): ClaimItem {
		let user = this.currentUserValue.user;
		return user?.claims[user.clientId]?.claims.find((x) => x.id == claimId);
	}

	canEditForm(form: WorkflowForm, formId?: string) {
		let currentStep = form?.workflowStep ?? form?.workflowSteps?.find((t) => t.isCurrent)?.name;
		if (currentStep) {
			currentStep = currentStep.toLowerCase();
		}
		if (formId == null) {
			formId = this.getFormKeyByName(form.workflowName);
		}

		return (
			form.declinedReason == null &&
			((form.createdBy == this.currentUserValue.user.id &&
				(currentStep == 'workflow started' || currentStep == 'draft')) ||
				(this.isAdminToEditForm(form.clientId, formId) && (this.isAdmin() || currentStep !== 'completed')))
		);
	}

	canEditFormBypassWorkflowStatus(form: WorkflowForm) {
		return this.isAdminToEditForm(form.clientId, this.getFormKeyByName(form.workflowName));
	}

	getClaimDepartments(clientId: string, formId: string) {
		let claim = this.getClaim(`${formId}-department`);
		if (claim) {
			return claim.departmentIds;
		}
		return [];
	}

	canEditFormByName(clientId: string, formName: string) {
		return this.canEditForm({
			workflowName: formName,
			allApprovers: [],
			clientId: clientId,
			completedDate: undefined,
			createdBy: '',
			createdDate: undefined,
			currentApprovers: [],
			declinedReason: null,
			id: '',
			isActive: true,
			isCompleted: false,
			requestedBy: '',
			workflowId: 0,
			workflowStep: '',
			workflowSteps: [],
		});
	}

	canApproveForm(form: WorkflowForm, clientId) {
		return this.isAdmin() || this.utilityService.isApprover(form, this.currentUserValue.user.id);
	}

	canSubmitForApproval(form: WorkflowForm, formId: string) {
		return (
			(form.workflowStep?.toLowerCase() == 'draft' || form.workflowStep?.toLowerCase() == 'workflow started') &&
			(form.createdBy == this.currentUserValue.user.id || this.isAdminToEditForm(form.clientId, formId))
		);
	}

	canSendForReview(form: WorkflowForm, userId: string) {
		let currentStep = form?.workflowSteps?.find((t) => t.isCurrent)?.name ?? form?.workflowStep;
		if (currentStep) {
			currentStep = currentStep.toLowerCase();
		}

		return (
			currentStep != 'workflow started' &&
			currentStep != 'completed' &&
			(this.utilityService.canSendForReview(form, userId) || this.isAdmin())
		);
	}

	loginAs(id: string) {
		return this.httpClient.post<UserLogin>(`${environment.apiUrl}authentication/secret-login-request`, { id }).pipe(
			map((userLogin) => {
				localStorage.clear();
				sessionStorage.clear();
				this.setClientIdIfEmpty(userLogin.user);
				sessionStorage.setItem(UserService.cookieName, JSON.stringify(userLogin));

				if (!localStorage.getItem(UserService.mappingName)) {
					this.getFormMappingAll().subscribe((data) => {
						this.setFormMappings(data);
					});
				}
				this.currentUserSubject.next(userLogin);
				return userLogin;
			})
		);
	}

	validate2FA(email: any, verificationCode: string): Observable<UserLogin> {
		return this.httpClient
			.post<UserLogin>(`${environment.apiUrl}authentication/2fa`, {
				email,
				verificationCode,
			})
			.pipe();
	}

	refreshAccessToken(userId: string, refreshToken: string): Observable<UserLogin> {
		return this.httpClient
			.post<UserLogin>(`${environment.apiUrl}authentication/refresh-access-token`, { userId, refreshToken })
			.pipe();
	}

	logoutApi(userId: string, refreshToken: string): Observable<UserLogin> {
		return this.httpClient
			.post<UserLogin>(`${environment.apiUrl}authentication/logout`, { userId, refreshToken })
			.pipe();
	}

	private setClientIdIfEmpty(user: User) {
		if (user.clients.length == 1) {
			user.clientId = user.clients[0].clientId;
			this.setClientId(user.clientId);
		}
	}
	getUserAccessReport(clientId: string, filter: any): Observable<any[]> {
		return this.httpClient
			.post<any[]>(`${environment.apiUrl}standard-report/clients/${clientId}/get-access-report`, filter)
			.pipe();
	}
	getAuditLogReport(clientId: string, filter: any): Observable<any[]> {
		return this.httpClient
			.post<any[]>(`${environment.apiUrl}standard-report/clients/${clientId}/get-audit-log-report`, filter)
			.pipe();
	}

	setMsToken(msToken: string) {
		localStorage.setItem(UserService.msTokenCookie, msToken);
	}

	getMsalAccessToken() {

		var msTokenKeys = localStorage.getItem(`msal.token.keys.${environment.msEntraClientId}`);
		if (msTokenKeys == null) { console.log('msalToken not available'); return null; }
		var accessTokenKey = JSON.parse(msTokenKeys).accessToken;
		var accessTokenObject = localStorage.getItem(accessTokenKey);
		var accessToken = JSON.parse(accessTokenObject).secret;
		return accessToken;
	}

	getMsalUserName() {
		var msalAccountKeys = localStorage.getItem('msal.account.keys');
		if (msalAccountKeys == null) { console.log('msal account keys not available'); return null; }
		var accountKey = JSON.parse(msalAccountKeys)[0];
		var msalAccount = JSON.parse(localStorage.getItem(accountKey));
		localStorage.setItem(UserService.msalAccount, JSON.stringify(msalAccount));
		var userName = msalAccount.username;
		return userName;
	}

	clearMsLogin() {
		localStorage.clear();
		sessionStorage.clear();
		datadogRum.clearUser();
	}

	isSSOLogin() {
		return this.currentUserValue.isSSOLogin;
	}

	getMsalAccount() {
		return JSON.parse(localStorage.getItem(UserService.msalAccount));
	}
}
