import { ErrorHandler, Injectable } from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
	constructor() {
		super();
	}

	handleError(error: Error) {
		console.log(error.message);
		datadogRum.addError(error.message);
	}
}
