import { environment } from 'environments/environment';
const glSegmentations: GlSegmentation[] = [
	{
		clientId: 'default',
		E1: 'Fund',
		E2: 'Fund Type',
		E3: 'Fund Group',
		E4: '',
		E5: '',
		E6: '',
		O1: 'Department',
		O2: 'Sub-Function',
		O3: 'Function',
		O4: '',
		O5: '',
		O6: '',
		A1: 'Account',
		A2: 'Sub-Object',
		A3: 'Object',
		A4: 'Character',
		A5: 'Account Type',
		A6: '',
		hasSubObject: true,
		hasSubFunction: true,
		hasFundGroup: true,
	},
	{
		clientId: environment.opalockaId,
		E1: 'Fund',
		E2: 'Fund Type',
		E3: 'Fund Group',
		E4: '',
		E5: '',
		E6: '',
		O1: 'Division',
		O2: 'Department',
		O3: 'Sub-Function',
		O4: 'Function',
		O5: '',
		O6: '',
		A1: 'Account',
		A2: 'Sub-Object',
		A3: 'Object',
		A4: 'Character',
		A5: 'Account Type',
		A6: '',
		hasSubObject: true,
		hasSubFunction: true,
		hasFundGroup: true,
	},
	{
		clientId: environment.frameworkGroupLLC,
		E1: 'Development',
		E2: 'Company',
		E3: 'Parent',
		E4: '',
		E5: '',
		E6: '',
		O1: 'Department',
		O2: 'Function',
		O3: '',
		O4: '',
		O5: '',
		O6: '',
		A1: 'Account',
		A2: 'Object',
		A3: 'Character',
		A4: 'Account Type',
		A5: '',
		A6: '',
		hasSubObject: false,
		hasSubFunction: false,
		hasFundGroup: true,
	},
	{
		clientId: environment.aclarianId,
		E1: 'Company',
		E2: 'Parent',
		E3: '',
		E4: '',
		E5: '',
		E6: '',
		O1: 'Department',
		O2: 'Division',
		O3: '',
		O4: '',
		O5: '',
		O6: '',
		A1: 'Account',
		A2: 'Sub-Object',
		A3: 'Object',
		A4: 'Character',
		A5: 'Account Type',
		A6: '',
		hasSubObject: false,
		hasSubFunction: false,
		hasFundGroup: false,
	},{
		clientId: environment.paSchools,
		E1: 'Fund',
		E2: 'Fund Type',
		E3: 'Fund Group',
		E4: '',
		E5: '',
		E6: '',
		O1: 'Function',
		O2: 'Division',
		O3: '',
		O4: '',
		O5: '',
		O6: '',
		A1: 'Account',
		A2: 'Object',
		A3: 'Character',
		A4: 'Account Type',
		A5: '',
		A6: '',
		hasSubObject: false,
		hasSubFunction: false,
		hasFundGroup: false,
	},
];
export { glSegmentations };

export class GlSegmentation {
	clientId: string;
	E1: string;
	E2: string;
	E3: string;
	E4: string;
	E5: string;
	E6: string;
	O1: string;
	O2: string;
	O3: string;
	O4: string;
	O5: string;
	O6: string;
	A1: string;
	A2: string;
	A3: string;
	A4: string;
	A5: string;
	A6: string;
	hasSubObject: boolean;
	hasSubFunction: boolean;
	hasFundGroup: boolean;
}
