<div class="logo">
	<button class="sidebar-minimizer" (click)="minimizeSidebar()"></button>
	<a href="https://www.aclarian.com" target="_blank" class="logo-custom">
		<div class="logo-image">
			<img src="../../assets/img/aclarian-logo.png" alt="aclarian logo" />
			<div class="golden-box-shape golden-box-bg"></div>
		</div>
	</a>
</div>
<div class="sidebar-wrapper">
	<div class="user">
		<div class="photo"></div>
		<div class="info" *ngIf="disableClientDD">
			<a href="#" class="collapsed">
				<span> {{ user?.firstName }} {{ user?.lastName }} </span>
			</a>
			<div class="clearfix"></div>
		</div>
		<div class="info" *ngIf="!disableClientDD">
			<a data-toggle="collapse" href="#collapseExample" class="collapsed">
				<span>
					{{ user?.firstName }} {{ user?.lastName }}
					<b class="caret"></b>
				</span>
			</a>
			<div class="clearfix"></div>
			<div class="collapse" id="collapseExample">
				<ul class="nav">
					<li class="container-fluid">
						<div class="row">
							<div class="col-md-12" *ngIf="this.user.clients?.length > 1 || this.isAdmin">
								<div class="form-group">
									<select
										class="form-control"
										(change)="clientSelected()"
										name="selectedClientId"
										[(ngModel)]="selectedClientId"
										[disabled]="disableClientDD"
									>
										<option value>Select Client</option>
										<option
											*ngFor="let client of clients"
											[selected]="client.id === selectedClientId"
											[value]="client.id"
										>
											{{ client.name }}
										</option>
									</select>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<ul class="nav" id="side-nav">
		<div class="col-md-12">
			<form [formGroup]="formGroup" style="font-size: 12px;">
				<mat-form-field style="    background: white;
				border-radius: 4px;
				width: 100%;
				padding:4px 12px 4px 12px;">
					<input
						matInput
						[matAutocomplete]="auto"
						[formControl]="searchForm"
						placeholder="Search by Form Title"
					/>
					<mat-autocomplete #auto="matAutocomplete">
						<mat-optgroup *ngFor="let group of filteredValues | async" [label]="group.title">
							<mat-option  style="height: 30px !important;"
								*ngFor="let form of group.children"
								[value]="form.title"
								(onSelectionChange)="handleUrl(form.path)"
							>
								<span style="font-size: 14px;">{{ form.title }}</span>
							</mat-option>
						</mat-optgroup>
					</mat-autocomplete>
				</mat-form-field>
			</form>
		</div>
		<ng-container *ngFor="let menuitem of menuItems">
			<li routerLinkActive="active" *ngIf="menuitem.enabled">
				<!--If is a single link-->
				<a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
					<p>{{ menuitem.title }}</p>
				</a>
				<!--If it have a submenu-->
				<a data-toggle="collapse" href="#{{ menuitem.collapse }}" *ngIf="menuitem.type === 'sub'">
					<p>{{ menuitem.title }}<b class="caret"></b></p>
				</a>

				<!--Display the submenu items-->
				<div
					[id]="menuitem.collapse"
					class="collapse collapse-menu"
					*ngIf="menuitem.type === 'sub'"
					data-parent="#side-nav"
					style="margin-left: 15px"
				>
					<ul class="nav" [id]="menuitem.collapse + '-ul'">
						<ng-container *ngFor="let childitem of menuitem.children">
							<li routerLinkActive="active" *ngIf="childitem.type === 'sub'" class="sub-sub">
								<a data-toggle="collapse" href="#{{ menuitem.collapse + childitem.collapse }}">
									<p style="font-size: 0.9em">{{ childitem.title }}<b class="caret"></b></p>
								</a>
							</li>
							<div
								*ngIf="childitem.type === 'sub'"
								id="{{ menuitem.collapse + childitem.collapse }}"
								class="collapse collapse-menu"
								[attr.data-parent]="'#' + menuitem.collapse"
							>
								<ul class="nav">
									<!--If is a single link-->
									<li
										class="sub-sub-item"
										*ngIf="!childitem?.children || childitem?.children.length == 0 || !hasEnabledItem(childitem)"
									>
										<a>
											<span style="font-size: 0.9em" class="sidebar-normal-sub">No Item Found</span>
										</a>
									</li>

									<ng-container *ngFor="let child of childitem?.children">
										<li class="sub-sub-item" routerLinkActive="active" [hidden]="!child.enabled">
											<a [routerLink]="menuitem.path + '/' + child.path">
												<span style="font-size: 0.9em" class="sidebar-normal-sub">{{ child.title }}</span>
											</a>
										</li>
									</ng-container>
								</ul>
							</div>
							<li *ngIf="childitem.type !== 'sub'" routerLinkActive="active">
								<a [routerLink]="menuitem.path + '/' + childitem.path">
									<span class="sidebar-normal">{{ childitem.title }}</span>
								</a>
							</li>
						</ng-container>
					</ul>
				</div>
			</li>
		</ng-container>
	</ul>
</div>
