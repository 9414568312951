<div id="test-environment" *ngIf="!isProduction">
	<h5 style="color: red">{{ environmentName }} ENVIRONMENT</h5>
</div>
<nav
	#navbar
	class="navbar navbar-expand-lg"
	[ngClass]="{ 'bg-white': open === true, 'navbar-transparent': open === false }"
>
	<div class="container-fluid">
		<div class="navbar-wrapper">
			<div style="margin-left: 10px !important">
				<button class="btn btn-icon btn-round" (click)="back()" *ngIf="showBackBtn()">
					<i class="nc-icon nc-minimal-left text-center"></i>
				</button>
			</div>

			<a class="navbar-brand" *ngIf="showTitle()" href="javascript:void(0)">{{ getTitle() }}</a>
		</div>
		<ul class="nav navbar-nav navbar-right ml-auto">
			<div class="d-flex">
				<li class="dropdown">
					<a class="dropdown-toggle" data-toggle="dropdown" href="#"
						><i class="fa fa-bell gold-font-color"></i
						><sup class="notification" *ngIf="unreadAnnouncements > 0">{{ unreadAnnouncements }}</sup>
					</a>
					<ul class="dropdown-menu">
						<li *ngFor="let announcement of announcements">
							<a href="/#/announcements/view">{{ announcement.title }}</a>
						</li>
						<li><a href="/#/announcements/view">All Announcements</a></li>
					</ul>
				</li>
				<li class="dropdown dropdown-with-icons">
					<a data-toggle="dropdown" href="#" class="dropdown-toggle" aria-expanded="false"
						><i class="fa fa-list gold-font-color"></i
					></a>
					<ul class="dropdown-menu dropdown-with-icons postion-absolute">
						<li (click)="logout()">
							<a href="javascript:void(0)" class="text-danger"><i class="fa fa-sign-out-alt"></i> Logout </a>
						</li>
					</ul>
				</li>
			</div>
		</ul>
	</div>
</nav>
