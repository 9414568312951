import {AfterContentChecked, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {NavService} from '../services/nav.service';
import {ClientsService} from '../services/clients.service';
import {Client} from '../models/Client';
import {Observable,Subscription, of} from 'rxjs';
import {UtilityService} from '../services/utility.service';
import {environment} from 'environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged,filter, startWith, switchMap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

declare var $: any;

//Metadata
export interface RouteInfo {
	path: string;
	title: string;
	type: string;
	collapse?: string;
	children?: ChildrenItems[];
	order?: number;
	enabled?: boolean;
	isLocalSection?: boolean;
}

export interface ChildrenItems {
	path: string;
	title: string;
	ab: string;
	type?: string;
	enabled?: boolean;
}

let misc: any = {
	navbar_menu_visible: 0,
	active_collapse: true,
	disabled_collapse_init: 0,
};

@Component({
	moduleId: module.id,
	selector: 'sidebar-cmp',
	templateUrl: 'sidebar.component.html',
	styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements AfterContentChecked, OnDestroy, OnInit {
	private subscription: Subscription;
	public menuItems: any[];
	user: any;
	isAdmin: boolean;
	clients: Client[];
	selectedClientId: string;
	disableClientDD: boolean = true;
	private toggleButton;
	private sidebarVisible: boolean;
	private screenSize = '';
	searchForm = new FormControl();
  	filteredForms: any[] = [];
	formGroup: FormGroup;
	searchText: Observable<string>;
	filteredValues: Observable<any[]>;
	constructor(
		private navService: NavService,
		private userService: UserService,
		private clientService: ClientsService,
		private utilityService: UtilityService,
		private element: ElementRef,
		private router: Router,
		private fb: FormBuilder
	) {
		this.user = this.userService.currentUserValue.user;

		this.selectedClientId = this.user.clientId ?? '';
		this.isAdmin = this.userService.hasRole('Aclarian Support');
		this.menuItems = this.navService.ROUTES;
		this.sidebarVisible = false;

		this.subscription = this.navService.routes.subscribe((data: RouteInfo[]) => {
			this.menuItems = data;
			this.updateMenuItems();
					
		});
		this.formGroup = this.fb.group({
			formSearch: [''] // Add other form controls here if needed
		  });
		
	}
	
	  private loadFormsDropDown(){
		this.searchText = this.searchForm.valueChanges.pipe(startWith(''), debounceTime(500), distinctUntilChanged());
		this.filteredValues = this.searchText.pipe(
			switchMap((val) => {
				return of(this.filterForms(val));
			})
		);
	  }
	  private filterForms(query: string): any[] {
		if(!query) return this.filteredForms;
		query = query.toLowerCase();
		const filteredData= JSON.parse(JSON.stringify(this.filteredForms)).map(item => {
			if (item.children) {
				item.children = item.children.filter(child => child.title.toLowerCase().includes(query));
				return item;
			}
			return null;
		}).filter(item => item !== null && item.children.length > 0);
		return filteredData;
	  }
	  extractTitleAndPath(items) {
		return items.filter(x=>x.children?.length > 0).map((item) => {
			const parentPath = item.path;
			let children = [];
			if(item.children.length > 0) {
				item.children.forEach((child) => {
					if (child.children) {
					  children.push(
						...child.children.map((subChild) => ({
						  ...subChild,
						  path: parentPath + '/' + subChild.path,
						}))
					  );
					} else {
					  children.push({
						...child,
						path: parentPath + '/' + child.path,
					  });
					}
				  });
				}
			
			
			return {
			  title: item.title,
			  path: item.path,
			  children: children
			};
		  });
	  }
	updateMenuItems() {
		if (this.isAdmin) {
			this.menuItems.forEach((x) => {
				if (!x.isLocalSection) {
					x.enabled = true;
					x.children?.forEach((y) => (y.enabled = true));
				}
			});
		}
		this.enableMenus(); // set visibility of menus
		for (const menu of this.menuItems) {
			let childMenus = ['report', 'form', 'data-management'];
			for (let child = 0; child < menu.children?.length; child++) {
				const element = menu.children[child];
				if (childMenus.includes(element.type)) {
					const reportSub = menu.children?.find((t) => t.collapse == element.type);
					if (!reportSub) {
						if (!menu.children) {
							menu.children = [];
						}

						if (element.type == 'report') {
							menu.children.push({
								order: 3,
								path: '/report',
								title: 'Reports',
								type: 'sub',
								collapse: 'report',
								icontype: 'nc-icon nc-single-copy-04',
								children: [element],
							});
						} else if (element.type == 'form') {
							menu.children.push({
								order: 1,
								path: '/form',
								title: 'Forms',
								type: 'sub',
								collapse: 'form',
								icontype: 'nc-icon nc-notes',
								children: [element],
							});
						} else if (element.type == 'data-management') {
							menu.children.push({
								order: 2,
								path: '/data-management',
								title: 'Data Management',
								type: 'sub',
								collapse: 'data-management',
								icontype: 'nc-icon nc-layers-3',
								children: [element],
							});
						}
					} else {
						if (!reportSub.children) {
							reportSub.children = [];
						}
						reportSub.children.push(element);
					}
				}
			}

			menu.children = menu.children
				?.filter((t) => !childMenus.includes(t.type))
				.sort((a, b) => (a.order < b.order ? -1 : 1));
		}
		this.filteredForms = this.extractTitleAndPath(this.menuItems);	
		this.loadFormsDropDown();
	}

	ngOnInit() {
		const navbar: HTMLElement = this.element.nativeElement;
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		if (body.classList.contains('sidebar-mini')) {
			misc.sidebar_mini_active = true;
		}

		this.updateMenuItems();
		this.disableClientDD = false;
		if (this.userService.isClientAdmin(this.selectedClientId) && !this.isAdmin && this.user.clients?.length == 0) {
			this.disableClientDD = true;
		} else if (this.user.clients?.length > 1 && !this.isAdmin) {
			this.clientService.getClientList().subscribe((data) => {
				this.clients = data
					.filter((t) => t.isActive)
					.filter((x) => this.user.clients.map((x) => x.clientId).includes(x.id));

			});
		} else if (this.isAdmin) {
			this.clientService.getClientList().subscribe((data) => {
				this.clients = data.filter((t) => t.isActive);
			});
		}


		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			if (event.urlAfterRedirects.startsWith('/')) {
				this.closeSideBar();
			}
		});
		if (window.innerWidth < 991) {
			this.closeSideBar();
		}
	}

	ngAfterContentChecked(): void {
		$('.sub-sub').removeClass('active');
		$('.sub-sub-item.active').parent().parent().prev('.sub-sub').first().addClass('active');
		let elementsToCollapse = document.getElementsByClassName('collapse-menu');
		if (elementsToCollapse) {
			for (let i = 0; i < elementsToCollapse.length; i++) {
				if (this.selectedClientId == environment.lakeHelenId ||
				this.selectedClientId == environment.gladesBOCC ||
				this.selectedClientId == environment.cityOfGroveland)
				{
					elementsToCollapse[i].classList.add('show');
				}
			}
		}
	}

	ngAfterViewInit() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	clientSelected() {
		this.userService.setClientId(this.selectedClientId == '' ? undefined : this.selectedClientId);
		if (this.selectedClientId) {
			this.utilityService.retrieveConfiguration(
				this.selectedClientId,
				() => {
					location.reload();
				},
				true
			);
		} else {
			location.reload();
		}
	}

	enableMenus() {
		this.menuItems
			.filter((x) => x.type == 'sub')
			.forEach((x) => {
				x.enabled = x.children?.some((y) => y.enabled) || false;
			});
	}

	hasEnabledItem(childitems) {
		return childitems?.children.some((x) => x.enabled);
	}

	ngOnDestroy(): void {
		try {
			this.subscription.unsubscribe();
		} catch {}
	}

	sidebarClose() {
		let html = document.getElementsByTagName('html')[0];
		if (this.toggleButton) {
			this.toggleButton.classList.remove('toggled');
		}
		this.sidebarVisible = false;
		html.classList.remove('nav-open');
		const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

		if (window.innerWidth < 991) {
			setTimeout(function () {
				mainPanel.style.position = '';
			}, 500);
		}
	}

	minimizeSidebar() {
		const body = document.getElementsByTagName('body')[0];
		const mainSidebarWrapper = document.getElementsByClassName('sidebar-pointer-events')[0];
		const sideBar = document.getElementsByClassName('sidebar')[0];
		const goldenBox = document.getElementsByClassName('golden-box-shape')[0];		
	
		if (misc.sidebar_mini_active === true) {
			body.classList.remove('sidebar-mini');
			sideBar.classList.add('removeSpace');
			goldenBox.classList.add('golden-box-bg'); 
			misc.sidebar_mini_active = false;
		} else {
			setTimeout(function () {
				body.classList.add('sidebar-mini');
				misc.sidebar_mini_active = true;
			}, 300);
			goldenBox.classList.remove('golden-box-bg');
			mainSidebarWrapper.classList.add('pointer-events-none');
			setTimeout(function () {
				mainSidebarWrapper.classList.remove('pointer-events-none');
			}, 1000);
		}

		// we simulate the window Resize so the charts will get updated in realtime.
		const simulateWindowResize = setInterval(function () {
			window.dispatchEvent(new Event('resize'));
		}, 180);

		// we stop the simulation of Window Resize after the animations are completed
		setTimeout(function () {
			clearInterval(simulateWindowResize);
		}, 1000);
	}
	
	handleWindowResize() {
		const specificWidth = 991;
		const windowWidth = window.innerWidth;
		const sideBar = document.getElementsByClassName('sidebar')[0];
		const body = document.getElementsByTagName('body')[0];

		if (windowWidth < specificWidth) {
			if (body.classList.contains('sidebar-mini')) {
				sideBar.classList.remove('removeSpace');
				sideBar.classList.add('addSpace');
			} else {
				if (this.screenSize == undefined || this.screenSize == 'large') {
					setTimeout(function () {
						body.classList.add('sidebar-mini');
						misc.sidebar_mini_active = true;
						sideBar.classList.add('addSpace');
						sideBar.classList.remove('removeSpace');
					}, 300);
				} else {
					sideBar.classList.remove('addSpace');
					sideBar.classList.add('removeSpace');
				}
			}
			this.screenSize = 'small';
		}
		if (windowWidth >= specificWidth) {
			this.screenSize = 'large';
			if (body.classList.contains('sidebar-mini')) {
				if (this.screenSize == 'small') {
					sideBar.classList.remove('addSpace');
					sideBar.classList.add('removeSpace');
				} else {
					sideBar.classList.remove('addSpace');
					sideBar.classList.add('removeSpace');
				}
			}
		}
	}

	closeSideBar() {
		const body = document.getElementsByTagName('body')[0];
		const sideBar = document.getElementsByClassName('sidebar')[0];

		if (window.innerWidth < 991) {
			sideBar.classList.add('addSpace');
			sideBar.classList.remove('removeSpace');
			setTimeout(function () {
				body.classList.add('sidebar-mini');
				misc.sidebar_mini_active = true;
			}, 300);
		}
	}
	handleUrl(path: any){
		this.router.navigateByUrl(path).then();
		this.loadFormsDropDown();
	}
}
